* {
    box-sizing: border-box;

    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0.42rem 0.72rem 0 0;
    justify-content: end;
    z-index: 10;
    @media (max-width: 900px) {
        padding: 0;
        top: auto;
        bottom: 0;
        padding-bottom: 0.67rem;
    }
    .text {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 0.14rem;
        color: #fff;
        line-height: 0.16rem;
        @media (max-width: 900px) {
            font-size: 0.2rem;
            line-height: 0.24rem;
            padding: 0;
        }
    }
    .logo_box {
        display: flex;
        align-items: center;
        @media (max-width: 900px) {
            display: none;
        }
        .logo {
            width: 0.5rem;
            height: 0.5rem;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .text {
            margin-left: 0.14rem;
        }
    }
    .link_box {
        @media (max-width: 900px) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .text {
            padding: 0 0.17rem;
            @media (max-width: 900px) {
                width: 33%;
                padding: 0;
                text-align: center;
            }
        }
    }
    .active {
        position: relative;
        @media (max-width: 900px) {
            color: #ffffff;
        }
        &::after {
            @media (max-width: 900px) {
                width: 0.8rem;
            }
            position: absolute;
            left: 50%;
            bottom: -0.1rem;
            transform: translate(-50%, 0);
            content: "";
            width: 0.66rem;
            height: 0.04rem;
            background: url("../images/border.png");
            background-size: cover;
            background-position: center center;
        }
    }
}

.page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
        justify-content: center;
        background: url("../images/bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center center;
    }
    .box {
        margin-left: 1.16rem;
        @media (max-width: 900px) {
            position: fixed;
            bottom: 3.69rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 0;
        }
        .title {
            width: 6rem;
            height: 1rem;
            margin-left: -.2rem;
            @media (max-width: 900px) {
                width: 6.62rem;
                height: 2.13rem;
                margin-left: 0;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (max-width: 900px) {
                    object-fit: contain;
                }
            }
        }
        .tip {
            font-family: "Manrope", sans-serif;
            font-weight: 400;
            font-size: 0.3rem;
            color: #ffffff;
            line-height: 0.41rem;
            opacity: 0.5;
            margin-top: 0.2rem;
            @media (max-width: 900px) {
                transform: translateY(-100%);
            }
        }
        .app_store {
            width: 2.6rem;
            height: 1rem;
            margin-top: 0.88rem;
            @media (max-width: 900px) {
                margin-top: 0.46rem;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .footer {
        position: fixed;
        bottom: 0.42rem;
        width: 59.7%;
        left: 50%;
        transform: translateX(-50%);
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        font-size: 0.22rem;
        color: #ffffff;
        line-height: 0.3rem;
        opacity: 0.5;
        text-align: center;
        z-index: 1;
        @media (max-width: 900px) {
            bottom: 1.65rem;
            width: 82.4%;
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: url("../images/box_bg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        background: url("../images/box_bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center center;
    }
    .box {
        width: 77.7%;
        height: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 900px) {
            justify-content: flex-start;
            padding: 1rem 0.47rem;
            width: 100%;
        }

        .title {
            font-family: "Roboto", serif;
            font-weight: 500;
            font-size: 0.4rem;
            color: #ffffff;
            line-height: 0.47rem;
            margin-bottom: 0.2rem;
            img {
                width: 0.64rem;
                margin-left: 0.2rem;
            }
            @media (max-width: 900px) {
                width: 100%;
            }
        }
        .pact-cont {
            width: 100%;
            height: 60%;
            overflow: scroll;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            @media (max-width: 900px) {
                width: 100%;
                height: 88%;
            }

            p,
            li {
                font-family: "Roboto", sans-serif;
                padding-bottom: 0.3rem;
                font-weight: 500;
                font-size: 0.16rem;
                line-height: 0.19rem;
                color: #f8efff;
                @media (max-width: 900px) {
                    font-size: 0.28rem;
                    line-height: 0.33rem;
                }
            }

            h2 {
                font-family: "Roboto", sans-serif;
                font-size: 0.2rem;
                line-height: 0.24rem;
                font-weight: 700;
                color: #ffffff;
                @extend .bold;
                margin-bottom: 0.14rem;
                margin-top: 0.26rem;
                border-radius: 0.12rem;
                @media (max-width: 900px) {
                    font-size: 0.3rem;
                    line-height: 0.35rem;
                }
            }

            strong {
                font-weight: bold;
            }
            table {
                border-collapse: collapse;
                width: 100%;
                th {
                    font-size: 0.18rem;
                    padding: 0.14rem 0.4rem;
                    background-color: rgba(#fff, 0.2);
                    text-align: left;
                    color: rgba(#fff, 0.5);
                    font-family: "Roboto", serif;
                    font-weight: 400;
                    @media (max-width: 900px) {
                        font-size: 0.22rem;
                        line-height: 0.25rem;
                        padding: 0.14rem 0.2rem;
                    }
                }
                .th {
                    width: 27.6%;
                }
                td {
                    padding: 0.16rem 0.4rem;
                    font-size: 0.16rem;
                    font-family: "Roboto", serif;
                    line-height: 0.2rem;
                    font-weight: bold;
                    color: #fff;
                    @media (max-width: 900px) {
                        font-size: 0.22rem;
                        line-height: 0.25rem;
                        padding: 0.16rem 0.2rem;
                    }
                }
                .td {
                    color: rgba(#fff, 0.8);
                }
            }
        }
    }

    .header-box {
        @media (max-width: 900px) {
            top: auto;
            bottom: 0.67rem;
            padding: 0 13%;
            font-size: 0.24rem;
            line-height: 0.28rem;
        }
        .link_box {
            @media (max-width: 900px) {
                width: 100%;
                justify-content: space-around;
            }
        }
        .text {
            font-size: 0.2rem;
            line-height: 0.24rem;
        }
    }
}
